import React, { useState } from 'react';
import login from "../Assets/login.jpg";
import axios from 'axios';  // You can use fetch instead of axios if preferred

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.travoolah.com/api/User/login', {
                email: email.trim(),
                password: password.trim()
            });
            
            const token = response.data.token;
            localStorage.setItem('jwtToken', token);  // Save token to localStorage

            // Redirect user to another page after login (e.g., dashboard)
            window.location.href = "/admin/dashboard";  
        } catch (err) {
            setError('Invalid credentials or server error.');
        }
    };

    return (
        <div className='mt-[70px] md:grid md:grid-cols-3 flex flex-col justify-center items-center'>
            <div className='col-span-1 hidden md:flex'>
                <img src={login} alt='login' className='h-[90vh] w-full object-cover' />
            </div>

            <div className='col-span-2 mt-10 justify-center items-center'>
                <div className='relative'>
                    <div className='w-5 h-5 bg-[#F26222] bg-opacity-25 absolute rounded-full top-3 left-[45%]'></div>
                    <h2 className="text-3xl mb-4 text-center py-3">Log In</h2>
                </div>
                <p className="mb-6 text-gray-600 text-center">Welcome! Please enter your details</p>
                <form className='flex flex-col w-10/12 mx-auto' onSubmit={handleLogin}>
                    {error && <div className="text-red-500 mb-4">{error}</div>}
                    <div className="mb-4">
                        <label className="block text-sm mb-2">Email</label>
                        <input
                            type="email"
                            placeholder="Enter your email"
                            className="w-full p-3 border border-gray-300 rounded"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm mb-2">Password</label>
                        <input
                            type="password"
                            placeholder="Enter your password"
                            className="w-full p-3 border border-gray-300 rounded"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="text-right mb-4">
                        <a href="#" className="text-orange-500 hover:underline">
                            Forgot password?
                        </a>
                    </div>
                    <button
                        type="submit"
                        className="w-full p-3 bg-orange-500 text-white rounded hover:bg-orange-600"
                    >
                        Log in
                    </button>
                    <p className="mt-6 text-center text-gray-600">
                        Don't have an account?{' '}
                        <a href="#" className="text-orange-500 hover:underline">
                            Sign up
                        </a>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default Login;