import React from 'react'
import Slider from '../Common/Slider'
import Property1 from "../../Assets/Property1.jpg";
import Property2 from "../../Assets/Property2.jpg";
import Property3 from "../../Assets/Property3.jpg";
import Property4 from "../../Assets/Property4.jpg";
import Property5 from "../../Assets/Property5.jpg";
import Property6 from "../../Assets/Property6.jpg";
import Property7 from "../../Assets/Property7.jpg";
import Property8 from "../../Assets/Property8.jpg";

function HeroSection() {
  const herSectionImages = [Property1 , Property2 , Property3 , Property4 , Property5 , Property6 , Property7 , Property8];
  return (
    <div className='relative flex flex-col'>
        <div className='z-20'>
        <Slider images={herSectionImages} height={'h-[70vh] sm:h-[70vh] md:h-[70vh] lg:h-[90vh] xl:h-[90vh]'}/>
        </div>
        <div className='absolute inset-0 flex flex-col justify-center items-center w-full gap-y-8 z-30'>
            <h1 className='text-white  text-2xl md:text-4xl font-semibold px-5 py-5'>Welcome to Travoolah</h1>
            <input
                type='button'
                value='Book Now'
                className='px-10 py-2 bg-[#F26222] text-white rounded-md hover:bg-[#A94214] transition duration-300 cursor-pointer'
            />
        </div>
    </div>
  )
}

export default HeroSection