import React from "react";
import call from "../../Assets/call.svg";
import whatsapp from "../../Assets/whatsapp.svg";

function Card({ img, title, address, duration ,oldPrice, newPrice }) {

  const whatsappMessage = "Hi, book me a trip to Kashmir";
  const phoneNumber = `${7006002553}`
  const handleCallClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleWhatsappClick = () => {
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <div className="flex flex-col rounded-lg shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] bg-white col-span-1 overflow-hidden hover:-translate-y-4 transition-all duration-300">
      <div className="relative pb-[60%] sm:pb-[56.25%]">
        <img src={img} alt="hotelImage" className="absolute top-0 left-0 w-full h-full object-cover" />
      </div>
      <div className="px-3 sm:px-4 py-3 flex flex-col gap-y-2 sm:gap-y-4">
        <div className="">
          <h4 className="font-semibold text-lg sm:text-xl">{title}</h4>
          <p className="text-[#878787] font-medium text-sm">{address}</p>
          <p className="text-[#878787] font-medium text-sm">Duration:{duration}</p>

        </div>

        <div className="flex gap-x-2 items-center">
          <p className="line-through text-[#878787] font-medium text-xs sm:text-sm">
            ₹{oldPrice}
          </p>
          <p className="font-bold text-lg">₹{newPrice}</p>
        </div>

        <div className="flex flex-col sm:flex-row gap-y-3 sm:gap-y-0 sm:gap-x-3 mt-2">

          <div
            onClick={handleCallClick}
            className="flex border-2 justify-center items-center gap-2 text-gray-500 px-3 py-2 rounded-md hover:bg-[#F26222] hover:text-white transition duration-300 cursor-pointer group w-full"
          >
            <img
              src={call}
              alt="callButton"
              className="w-4 h-4 sm:w-5 sm:h-5 group-hover:invert transition duration-300"
            />
            <span className="truncate text-sm sm:text-base whitespace-nowrap">Call Now</span>
          </div>

          <div
            onClick={handleWhatsappClick}
            className="flex border justify-center items-center gap-2 px-3 py-2 bg-[#F26222] text-white rounded-md hover:bg-[#A94214] transition duration-300 cursor-pointer w-full"
          >
            <img src={whatsapp} alt="whatsappButton" className="w-4 h-4 sm:w-5 sm:h-5" />
            <span className="text-sm sm:text-base">WhatsApp</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
