import React from 'react'

function TermsandConditions() {
    return (
        <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden">
            <div className="px-6 py-8">
              <h1 className="text-3xl font-bold text-gray-900 mb-6">Terms and Conditions</h1>
              
              <p className="text-sm text-gray-600 mb-4">
                <strong>Effective Date:</strong> June 1, 2023<br />
                <strong>Last Updated:</strong> June 1, 2023
              </p>
    
              <p className="text-gray-700 mb-4">
                Welcome to <strong className="text-indigo-600">Travoolah</strong>. These Terms and Conditions (the "Agreement")
                govern your use of our website, mobile applications, and services. By accessing or using Travoolah's platform,
                you agree to these terms. If you do not agree, you may not use our services.
              </p>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Definitions</h2>
                <ul className="list-disc list-inside text-gray-700 ml-4">
                  <li><strong>"We", "Us", "Our"</strong> refers to Travoolah.</li>
                  <li><strong>"You", "Customer", "User"</strong> refers to the individual using our services.</li>
                  <li><strong>"Services"</strong> refer to the travel-related services, including but not limited to, hotel bookings, car and bike rentals, activities, event management, and destination wedding packages provided through Travoolah's platform.</li>
                </ul>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. Booking Policies</h2>
                
                <h3 className="text-xl font-medium text-gray-700 mb-2">2.1 Hotel & Villa Bookings</h3>
                <ul className="list-disc list-inside text-gray-700 mb-4 ml-4">
                  <li>Bookings made via Travoolah are subject to availability and confirmation from the respective accommodation provider.</li>
                  <li>Special requests (e.g., room preferences, extra amenities) are subject to the hotel's approval and are not guaranteed.</li>
                </ul>
    
                <h3 className="text-xl font-medium text-gray-700 mb-2">2.2 Cancellation & Refunds</h3>
                <ul className="list-disc list-inside text-gray-700 mb-4 ml-4">
                  <li><strong>Flexible Rates:</strong> Full refund if canceled within the time specified during booking.</li>
                  <li><strong>Non-refundable Rates:</strong> No refund will be provided for cancellations or no-shows.</li>
                  <li>Refunds, if applicable, will be processed within 7-10 working days.</li>
                </ul>
    
                <h3 className="text-xl font-medium text-gray-700 mb-2">2.3 Modifications</h3>
                <p className="text-gray-700 mb-4">
                  Modifications to bookings (e.g., date changes) are subject to availability and additional charges as per the service provider's policy.
                </p>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Payments</h2>
                <ul className="list-disc list-inside text-gray-700 ml-4">
                  <li>Payments must be completed in full at the time of booking via our secure online payment gateway.</li>
                  <li>We accept major credit and debit cards, digital wallets, and UPI as payment methods.</li>
                  <li>Travoolah does not store your card details. All payments are processed via secure third-party gateways.</li>
                </ul>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. User Conduct</h2>
                <p className="text-gray-700 mb-4">
                  By using Travoolah's platform, you agree to:
                </p>
                <ul className="list-disc list-inside text-gray-700 ml-4">
                  <li>Provide accurate and complete information.</li>
                  <li>Use the platform for lawful purposes.</li>
                  <li>Not attempt to harm or disrupt the platform through unauthorized access or any illegal activities.</li>
                </ul>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Pricing and Charges</h2>
                <ul className="list-disc list-inside text-gray-700 ml-4">
                  <li>All prices listed on Travoolah's website are inclusive of applicable taxes unless otherwise specified.</li>
                  <li>Prices are subject to change at any time. However, once a booking is confirmed, the price will not be altered unless influenced by factors beyond our control (e.g., governmental tax changes).</li>
                </ul>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Liability</h2>
                <ul className="list-disc list-inside text-gray-700 ml-4">
                  <li>Travoolah acts only as an intermediary between users and service providers (e.g., hotels, transport services). We are not liable for any direct or indirect damages arising from the use of these services.</li>
                  <li>Travoolah will not be responsible for loss of personal belongings, delays, accidents, or any other incidents during the travel booked through the platform.</li>
                </ul>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">7. Force Majeure</h2>
                <p className="text-gray-700 mb-4">
                  Travoolah is not liable for failure to fulfill obligations due to circumstances beyond its control, including but not limited to natural disasters, strikes, wars, government actions, or any unforeseen events.
                </p>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">8. Intellectual Property</h2>
                <ul className="list-disc list-inside text-gray-700 ml-4">
                  <li>All content on the Travoolah platform, including but not limited to text, logos, images, designs, and software, are the intellectual property of Travoolah.</li>
                  <li>Unauthorized use of Travoolah's intellectual property is strictly prohibited.</li>
                </ul>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">9. Data Protection</h2>
                <p className="text-gray-700 mb-4">
                  Your personal data is processed in accordance with our <strong>Privacy Policy</strong>. We comply with the <strong>Information Technology Act, 2000</strong>, and other applicable Indian regulations.
                </p>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">10. Governing Law and Jurisdiction</h2>
                <p className="text-gray-700 mb-4">
                  This Agreement will be governed by and construed in accordance with the laws of <strong>India</strong>. Any disputes arising from or related to this Agreement shall be subject to the exclusive jurisdiction of courts in Bangalore, India.
                </p>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">11. Amendments</h2>
                <p className="text-gray-700 mb-4">
                  Travoolah reserves the right to update or modify these Terms and Conditions at any time. Changes will be effective upon posting on our website. We encourage you to review these terms periodically.
                </p>
              </section>
    
              <section className="mb-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">12. Contact Us</h2>
                <p className="text-gray-700 mb-4">
                  If you have any questions regarding these Terms and Conditions, please reach out to us at:
                </p>
                <ul className="list-none text-gray-700 ml-4">
                  <li><strong>Email:</strong> terms@travoolah.com</li>
                  <li><strong>Phone:</strong> +91 123 456 7890</li>
                </ul>
              </section>
            </div>
          </div>
        </div>
      );
}

export default TermsandConditions