// VideographyServices component
import React , {useState , useEffect}from 'react'
import Card from '../Common/Card'
import axios from 'axios';

function VideographyServices() {
  const [videoShoots , setVideoShootState] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Shoot");
      // Assuming the data is an array, set it directly
      console.log("resp" , response);
      setVideoShootState(response.data);
    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };
  
    useEffect(() => {
      getData();
    }, []);


  return (
    <div className='flex justify-center px-4 sm:px-5 mt-10 sm:mt-20'>
      <div className='w-full max-w-6xl flex flex-col gap-y-5'>
        <h3 className='text-[#353535] text-xl sm:text-2xl md:text-3xl font-semibold text-center px-4 py-3 sm:py-5'>
        Videography Services
        </h3>
        <div className='relative py-8'>
          <div className='flex overflow-x-auto pb-4 gap-4 sm:gap-6 snap-x justify-start scroll-pl-4'>
       {/* <Card img={Lalit} title={"The Lalit Grand Palace"} address={"Gupkar Rd, Srinagar, J&K 190001"} oldPrice={"7999"} newPrice={"4999/Night"}/> */}
       {videoShoots.length > 0 ? videoShoots
              .filter((event) => event.shootCategory?.name === "VideographyServices") // Filter by category name
              .map((event, index) => (
                <div key={index} className='flex-none w-[280px] sm:w-[320px] snap-start'>
                  <Card 
                    img={`https://api.travoolah.com/${event.image}`} // Adjust the image path if necessary
                    title={event.title} 
                    duration={event.duration}
                    oldPrice={`${event.price + 1500}`}
                    newPrice={event.price} 
                    address={event.description}
                  />
                </div>
              )):  <p>No data avalible</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideographyServices