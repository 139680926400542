import React from 'react'
import LeftNav from './LeftNav'
import { Outlet } from 'react-router-dom'

function AdminDashboardWrapper() {
  return (
    <div className='pt-16'>
      <div className='grid grid-cols-4'>
        <LeftNav />
        <div className="col-span-3">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default AdminDashboardWrapper