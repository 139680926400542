import React, { useState, useEffect } from 'react'
import Card from '../Common/Card'
import axios from 'axios';

function WellnessRetreat() {
  const [wellnessRetreat, setWellnessRetreat] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await axios.get("https://api.travoolah.com/api/Relaxation");
        console.log("API response:", response.data);
        setWellnessRetreat(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching wellness retreat data:", error);
        setError("Failed to fetch data. Please try again later.");
        setLoading(false);
      }
    };

    getData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const filteredRetreats = wellnessRetreat.filter(
    (retreat) => retreat.relaxationCategory?.name?.toLowerCase() === "wellnessretreat"
  );

  return (
    <div className='flex justify-center px-4 sm:px-5 mt-10 sm:mt-20'>
      <div className='w-full max-w-6xl flex flex-col gap-y-5'>
        <h3 className='text-[#353535] text-xl sm:text-2xl md:text-3xl font-semibold text-center px-4 py-3 sm:py-5'>
          Wellness Retreat Packages
        </h3>
        <div className='relative'>
          <div className='flex overflow-x-auto pb-4 gap-4 sm:gap-6 snap-x justify-center'>
            {filteredRetreats.length > 0 ? (
              filteredRetreats.map((retreat, index) => (
                <div key={index} className='flex-none w-[280px] sm:w-[320px] snap-start'>
                  <Card 
                    img={`https://api.travoolah.com/${retreat.image}`}
                    title={retreat.title} 
                    duration={retreat.duration}
                    oldPrice={`${retreat.price + 1500}`}
                    newPrice={retreat.price} 
                    address={retreat.description}
                  />
                </div>
              ))
            ) : (
              <p>No wellness retreat packages available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WellnessRetreat