import React, { useState , useEffect} from 'react'
import Card from '../Common/Card'
import axios from 'axios';

function HotelPackages() {

  const [travelPackage , setTravelPackage] = useState([]);

    // Define an async function to fetch data
    const getData = async () => {
      try {
        const response = await axios.get("https://api.travoolah.com/api/TravelPackage");
        // Assuming the data is an array, set it directly
        console.log("resp" , response);
        setTravelPackage(response.data);
      } catch (error) {
        console.error("Error fetching adventure data:", error);
      }
    };
    
      useEffect(() => {
        getData();
      }, []);
  


  return (
    <div className='flex justify-center px-5 py-10'>
        <div className='w-10/12 flex flex-col gap-y-10 py-14'>
            <span className='mx-auto'>
                <h3 className='text-[#353535] text-2xl md:text-3xl font-semibold px-5 py-5'>Our Travel Packages</h3>
            </span>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full'>
            {travelPackage
            .slice(0, 3) // Limit to the first three records directly
            .map((event, index) => (
              <Card 
              img={`https://api.travoolah.com/${event.image}`} // Adjust the image path if necessary
              title={event.title}
              duration={event.duration} 
              oldPrice={`${event.price + 1500}`}
              newPrice={event.price} 
              address={event.description}
            />
            ))}
            </div>
        </div>
    </div>
  )
}

export default HotelPackages