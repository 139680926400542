import React from 'react'
import WellnessRelaxationTopSec from '../Components/WellnessRelaxation/WellnessRelaxationTopSec'
import WellnessRetreat from '../Components/WellnessRelaxation/WellnessRetreat'
import NatureEmersion from "../Components/WellnessRelaxation/NatureEmersion"
import YogaRelaxation from '../Components/WellnessRelaxation/YogaRelaxation'
import GeneralOutdoor from '../Components/WellnessRelaxation/GeneralOutdoor'
function WellnessPage() {
  return (
    <div className="w-full overflow-x-hidden">
        <WellnessRelaxationTopSec />
        <WellnessRetreat />
        <NatureEmersion />
        <YogaRelaxation />
        <GeneralOutdoor />
    </div>
  )
}

export default WellnessPage