import React from 'react'
import Adventure from "../../Assets/adventure.jpg"
function AdventurePagetTopSec() {

  return (
    <div className='relative flex flex-col'>
      <div className='z-20'>
        <img src={Adventure} alt='bg' className='h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh] xl:h-[90vh] w-full object-cover' />
      </div>
      <div className='absolute inset-0 flex flex-col justify-center items-center w-full gap-y-4 sm:gap-y-8 z-30 bg-black bg-opacity-40'>
        <h1 className='text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold px-5 py-3 text-center'>Adventure Activities</h1>
        <button className='px-6 sm:px-10 py-2 bg-[#F26222] text-white rounded-md hover:bg-[#A94214] transition duration-300 cursor-pointer text-sm sm:text-base'>
          Call Now
        </button>
      </div>
    </div>
  )
}

export default AdventurePagetTopSec