import React from 'react'

function Contact() {
  return (
    <div className='flex flex-col pt-28 w-full h-full'>
      <form className='flex flex-col w-11/12 mx-auto'>

        {/* Name */}

          <div className='flex flex-col'>
            <label htmlFor='Name'>Name</label>
            <input type='text' id='Name' className='w-1/2 bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow' 
            placeholder='Your name'/>
          </div>

          {/* Email */}

          <div className='flex flex-col'>
            <label htmlFor='Name'>Email</label>
            <input type='text' id='Name' className='w-1/2 bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow' 
            placeholder='Email'/>
          </div>
          {/* Phone */}

          <div className='flex flex-col'>
            <label htmlFor='Name'>Phone</label>
            <input type='text' id='Name' className='w-1/2 bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow' 
            placeholder='+91 123 456 7890'/>
          </div>

          {/* Arrivate Date */}

          {/* Departure Date */}

          {/* where visit */}

          {/* Number of Adults */}

          {/* Number of Childrens */}
      </form>
    </div>
  )
}

export default Contact