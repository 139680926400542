import React, { useEffect, useState } from 'react';
import axios from "axios";
import { PlusCircle, Edit2, Trash2 } from 'lucide-react';

function HotelsAdmin() {
    const [travelPackages, setTravelPackages] = useState([]);
    const [transportForm, setTransportForm] = useState({
      Id: null,
      image: null,
      title: '',
      price: '',
    });

    const fetchTravel = async () => {
        try {
          const response = await axios.get('https://api.travoolah.com/api/Hotel');
          setTravelPackages(response.data);
        } catch (e) {
          console.error("error", e);
        }
    };
    
    useEffect(() => {
        fetchTravel();
    }, []);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTransportForm({ ...transportForm, [name]: value });
    };
    
    const handleImageChange = (e) => {
        setTransportForm({ ...transportForm, image: e.target.files[0] });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
    
        if (transportForm.Id) {
          formData.append("Id", transportForm.Id);
        }
        formData.append("title", transportForm.title);
        formData.append("description", transportForm.description);
        formData.append("duration", transportForm.duration);
        formData.append("price", transportForm.price);
    
        if (transportForm.image) {
          formData.append("imageFile", transportForm.image);
        }
    
        try {
          if (transportForm.Id) {
            await axios.put(`https://api.travoolah.com/api/Hotel/${transportForm.Id}`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' }
            });
          } else {
            await axios.post('https://api.travoolah.com/api/Hotel', formData, {
              headers: { 'Content-Type': 'multipart/form-data' }
            });
          }
          resetForm();
          fetchTravel();
        } catch (error) {
          console.error("Error submitting form:", error);
        }
    };
    
    const handleEdit = (pkg) => {
        setTransportForm({
          Id: pkg.id,
          title: pkg.title,
          price: pkg.price,
          image: null
        });
    };
    
    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this package?")) {
          try {
            await axios.delete(`https://api.travoolah.com/api/Hotel/${id}`);
            fetchTravel();
          } catch (error) {
            console.error("Error deleting package:", error);
          }
        }
    };
    
    const resetForm = () => {
        setTransportForm({
          Id: null,
          image: null,
          title: '',
          price: '',
        });
    };
    
    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">Hotel Packages</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700" htmlFor="user_avatar">Upload Image</label>
              <input
                id="user_avatar"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="mt-1 block w-full text-sm text-gray-500
                           file:mr-4 file:py-2 file:px-4
                           file:rounded-md file:border-0
                           file:text-sm file:font-semibold
                           file:bg-blue-50 file:text-blue-700
                           hover:file:bg-blue-100"
              />
            </div>
            <input type='text' name='title' value={transportForm.title} onChange={handleChange} placeholder='Title' required className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500" />
            <input type='text' name='duration' value={transportForm.duration} onChange={handleChange} placeholder='Duration' required className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500" />
            <textarea name='description' value={transportForm.description} onChange={handleChange} placeholder='Description' required className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 h-24" />
            <input type='text' name='price' value={transportForm.price} onChange={handleChange} placeholder='Price' required className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500" />
            <button type="submit" className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300 flex items-center justify-center">
              <PlusCircle className="mr-2" size={20} />
              {transportForm.Id ? 'Update Package' : 'Add Package'}
            </button>
          </form>
    
          <h3 className='text-xl font-semibold mt-8 mb-4 text-gray-800'>Existing Hotel Packages</h3>
          <ul className="divide-y divide-gray-200">
            {travelPackages.map(pkg => (
              <li key={pkg.id} className="py-4 flex justify-between items-center">
                <span className="text-lg text-gray-700">{pkg.title}</span>
                <div>
                  <button onClick={() => handleEdit(pkg)} className="bg-yellow-500 text-white rounded-md p-2 mr-2 hover:bg-yellow-600 transition duration-300">
                    <Edit2 size={18} />
                  </button>
                  <button onClick={() => handleDelete(pkg.id)} className="bg-red-500 text-white rounded-md p-2 hover:bg-red-600 transition duration-300">
                    <Trash2 size={18} />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
    );
}

export default HotelsAdmin;
