import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import AdminDashboardWrapper from './AdminDashboardWrapper';
import TravelPackageForm from './TravelPagckageForm';
import TransportationForm from './TransportationForm';
import PhotographForm from './PhotographForm';
import EventManagementForm from './EventManagementForm';
import AdventureForm from './AdventureForm';
import Wellness from './Wellness';
import HotelsAdmin from './HotelsAdmin';
import VillasAdmin from './VillasAdmin';

function AdminRoutes() {
  const token = localStorage.getItem('jwtToken');

  if (!token) {
    return <Navigate to="/admin/login" replace />;
  }

  return (
    <Routes>
      <Route path="/" element={<AdminDashboardWrapper />}>
        <Route index element={<Navigate to="dashboard" replace />} />
        <Route path="dashboard" element={<Outlet />} />
        <Route path='hotels' element={<HotelsAdmin />} />
        <Route path='villas' element={<VillasAdmin />} />
        <Route path="travel" element={<TravelPackageForm />} />
        <Route path="transport" element={<TransportationForm />} />
        <Route path="shoots" element={<PhotographForm />} />
        <Route path="event" element={<EventManagementForm />} />
        <Route path="adventure" element={<AdventureForm />} />
        <Route path="wellness" element={<Wellness />} />
      </Route>
      <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
    </Routes>
  );
}

export default AdminRoutes;