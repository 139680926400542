import React from 'react'
import HeroSection from '../Components/HeroSection/HeroSection'
import HotelPackages from '../Components/HeroSection/HotelPackages'
import Villas from '../Components/HeroSection/Villas'
import CarsPackages from '../Components/HeroSection/CarsPackages'
import LetsTravel from '../Components/HeroSection/LetsTravel'

function Homepage() {
  return (
    <>
        <HeroSection/>
        <HotelPackages/>
        <Villas/>
        <CarsPackages />
        <LetsTravel />
    </>
  )
}

export default Homepage