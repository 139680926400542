import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios'; // Import axios
import signup from "../Assets/signup.jpg";

function SignUp() {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [userName, setUserName] = useState('');  // Add state for UserName
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    
    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    // Async function to register the user using axios
    async function registerUser(userData) {
        try {
            const response = await axios.post('https://api.travoolah.com/api/User/register', userData, {
                headers: { 'Content-Type': 'application/json' }
            });

            alert("User registered successfully!");
            window.location.href = '/admin/login';  
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                const errors = error.response.data.errors;
    
                // If errors is an object, extract and display its values
                if (typeof errors === 'object' && !Array.isArray(errors)) {
                    const errorMessages = Object.values(errors).flat(); // Convert object values to array
                    alert(errorMessages.join("\n"));
                } else if (Array.isArray(errors)) {
                    // If it's already an array, join and display
                    alert(errors.join("\n"));
                }
            } else {
                console.log("Registration failed with a generic error:", error);
                alert("An error occurred while creating the account.");
            }
        }
    }

    // Form submit handler
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        // Include userName in the userData object
        const userData = { userName, email, password };  
        await registerUser(userData);  // Call the async function
    };

    return (
        <div className='mt-[70px] md:grid md:grid-cols-3 flex flex-col justify-center items-center'>
            {/* Left Side Image */}
            <div className='col-span-1 hidden md:flex'>
                <img src={signup} alt='Sign Up' className='h-[90vh] w-full object-cover' />
            </div>

            {/* Right Side Form */}
            <div className='col-span-2 mt-10 justify-center items-center'>
                <div className='relative'>
                    <div className='w-5 h-5 bg-[#F26222] bg-opacity-25 absolute rounded-full top-3 left-[44%]'></div>
                    <h2 className="text-3xl mb-4 text-center py-3">Sign Up</h2>
                </div>
                <p className="mb-6 text-gray-600 text-center">Welcome! Please enter your details</p>
                <form className='flex flex-col w-10/12 mx-auto' onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm mb-2">User Name</label>  {/* Add UserName field */}
                        <input
                            type="text"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            placeholder="Enter your username"
                            className="w-full p-3 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm mb-2">Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            className="w-full p-3 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm mb-2">Password</label>
                        <div className="relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your password"
                                className="w-full p-3 border border-gray-300 rounded pr-10"
                                required
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600"
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm mb-2">Confirm Password</label>
                        <div className="relative">
                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm your password"
                                className="w-full p-3 border border-gray-300 rounded pr-10"
                                required
                            />
                            <button
                                type="button"
                                onClick={toggleConfirmPasswordVisibility}
                                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600"
                            >
                                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className="w-full p-3 bg-orange-500 text-white rounded hover:bg-orange-600"
                    >
                        Create Account
                    </button>
                    <p className="mt-6 text-center text-gray-600">
                        Already have an account?{' '}
                        <a href="/login" className="text-orange-500 hover:underline">
                            Login
                        </a>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default SignUp;