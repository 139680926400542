import React , {useState , useEffect}from 'react'
import VehicleCards from '../Common/VehicleCards'
import axios from 'axios';

function BikeRentals() {

  const [bikeState , setBikeState] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Transportation");
      setBikeState(response.data);
    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };
  
    useEffect(() => {
      getData();
    }, []);

  return (
    <div className='flex justify-center'>
        <div className='w-10/12 flex flex-col gap-y-2'>
            <span className='mx-auto'>
                <h3 className='text-[#353535] text-2xl md:text-3xl font-semibold px-5 py-5'>Bike Rentals</h3>
            </span>
            <div className='mx-auto  md:grid md:grid-cols-3 md:gap-x-5 lg:gap-x-10 flex flex-col gap-y-5  px-3 py-5'>
                {/* <VehicleCards img={Lalit} title={"The Lalit Grand Palace"} AirCond={"Gupkar Rd, Srinagar, J&K 190001"} seats={"7999"} LuggageSp={"4999/Night"}/> */}
                {bikeState.length > 0 ? bikeState
              .filter((event) => event.transportationCategory?.name === "Bikes") 
              .map((event, index) => (
                <div key={index} className='flex-none w-[280px] sm:w-[320px] snap-start'>
                  <VehicleCards 
                    img={`https://api.travoolah.com/${event.image}`}
                    title={event.title} 
                    seats={event.seats} 
                    LuggageSp={event.space}
                  />
                </div>
              )):  <p>No data avalible</p>}
            </div>
        </div>
    </div>
  )
}

export default BikeRentals