import React from 'react'
import CarRental from '../Components/Transportation/CarRental'
import BikeRentals from '../Components/Transportation/BikeRentals'
import CabsRental from '../Components/Transportation/CabsRental'


function TransportPage() {
  return (
    <>
        <CarRental />
        <BikeRentals />
        <CabsRental />
    </>
  )
}

export default TransportPage