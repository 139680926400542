import React , {useState , useEffect} from 'react'
import Card from '../Common/Card'
import axios from 'axios';
function HotelPage() {

    const [customTravel , setCustomTravelState] = useState([]);

    // Define an async function to fetch data
    const getData = async () => {
      try {
        const response = await axios.get("https://api.travoolah.com/api/Hotel");
        console.log("resp" , response);
        setCustomTravelState(response.data);
      } catch (error) {
        console.error("Error fetching Hotel data:", error);
      }
    };
    
      useEffect(() => {
        getData();
      }, []);
  

  return (
    <div className='flex flex-col py-5'>
    <div className='text-center'> 
      <h3 className='text-[#353535] text-2xl md:text-3xl font-semibold px-5 py-5'>Hotels</h3>
    </div>
    <div className='grid grid-cols-2 lg:grid-cols-3 gap-6 px-5 py-5'>
      {/* <Card img={Lalit} title={"The Lalit Grand Palace"} address={"Gupkar Rd, Srinagar, J&K 190001"} oldPrice={"7999"} newPrice={"4999/Night"}/> */}
        {customTravel.length > 0 ?
            customTravel.map((event, index) => (
              <div key={index} className='flex-none w-[280px] sm:w-[320px] snap-start'>
                <Card 
                  img={`https://api.travoolah.com/${event.image}`} // Adjust the image path if necessary
                  title={event.title}
                  duration={event.duration} 
                  oldPrice={`${event.price + 1500}`}
                  newPrice={event.price} 
                  address={event.description}
                />
              </div>
            )):  <p>No data avalible</p>}
    </div>
  </div>
  )
}

export default HotelPage