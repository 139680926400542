import './App.css';
import Navbar from './Components/Common/Navbar';
import Homepage from './Pages/Homepage';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from './Pages/Login';
import SignUp from './Pages/SignUp';
import TravelPackagesPage from './Pages/TravelPackagesPage';
import TransportPage from './Pages/TransportPage';
import PhotoShoots from './Pages/PhotoShoots';
import Footer from './Components/Common/Footer';
import EventManagementPage from './Pages/EventManagementPage';
import AdventrePage from './Pages/AdventrePage';
import WellnessPage from './Pages/WellnessPage';
import AdminRoutes from './Pages/Admin/AdminRoutes'
import { RecoilRoot} from "recoil";
import TermsandConditions from './Components/Common/TermsandConditions';
import PrivacyPolicy from './Components/Common/PrivacyPolicy';
import HotelsPage from './Pages/HotelsPage';
import Villas from './Pages/Villas';
import Contact from './Pages/Contact';

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <div className='scroll-smooth'>
          <Navbar />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path='/hotels' element={<HotelsPage/>}/>
            <Route path='/villas' element={<Villas />} />
            <Route path="/travel-packages" element={<TravelPackagesPage />} />
            <Route path='/transportation' element={<TransportPage />} />
            <Route path='/photoShoots' element={<PhotoShoots />} />
            <Route path='/eventManagement' element={<EventManagementPage />} />
            <Route path='/adventure' element={<AdventrePage />} />
            <Route path='/wellness' element={<WellnessPage />} />
            <Route path='/terms-conditions' element={<TermsandConditions />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
            <Route path='/contact' element={<Contact/>} />
            {/* admin routes */}
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/signUp" element={<SignUp />} />

            {/* Protected route for Admin Dashboard */}
            <Route
              path="/admin/*"
              element={<AdminRoutes />}
            />
          </Routes>
          <Footer />
        </div>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
