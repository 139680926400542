import { atom } from "recoil";

export const navSelected = atom({
    key: "navSelected",
    default: "/",
})

export const openMenu = atom({
    key: "openMenu",
    default: false,
})