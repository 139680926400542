import React , {useState , useEffect}from 'react'
import Card from '../Common/Card'
import Lalit from "../../Assets/Lalit.jpg"
import axios from 'axios';

function OtherActivities() {

  const [otherAct , setOtherAct] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Adventure");
      // Assuming the data is an array, set it directly
      setOtherAct(response.data);
    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };
  
    useEffect(() => {
      getData();
      console.log("data" , otherAct);
    }, []);


      return (
        <div className='flex justify-center px-4 sm:px-5 mt-10 sm:mt-20'>
          <div className='w-full max-w-6xl flex flex-col gap-y-5'>
            <h3 className='text-[#353535] text-xl sm:text-2xl md:text-3xl font-semibold text-center px-4 py-3 sm:py-5'>Other Activities</h3>
            <div className='relative'>
              <div className='flex overflow-x-auto pb-4 gap-4 sm:gap-6 snap-x'>
              {otherAct.length > 0 ? otherAct
              .filter((otherAct) => otherAct.adventureCategory?.name === "Others") 
              .map((adventure, index) => (
                <div key={index} className='flex-none w-[280px] sm:w-[320px] snap-start'>
                  <Card 
                    img={`https://api.travoolah.com/${adventure.image}`} 
                    title={adventure.title} 
                    oldPrice={adventure.price} 
                    newPrice={adventure.price}
                  />
                </div>
              )) :  <p>No data avalible</p>}
              </div>
            </div>
          </div>
        </div>
      )
}

export default OtherActivities