import React , {useState , useEffect} from 'react'
import Vilas from "../../Assets/villas.jpg"
import Card from '../Common/Card'
import axios from 'axios';


function Villas() {

  const [adventure , setAdventure] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Adventure");
      // Assuming the data is an array, set it directly
      setAdventure(response.data);
    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };
  
    useEffect(() => {
      getData();
    }, []);

  return (
    <div className='relative w-full min-h-screen flex flex-col items-center'>
      <div 
        className='absolute inset-0 bg-cover bg-center bg-no-repeat'
        style={{ backgroundImage: `url(${Vilas})` }}
      ></div>
      <div className='absolute inset-0 bg-black bg-opacity-50'></div>
      <div className='relative z-10 w-full min-h-screen flex items-center justify-center py-20'>
        <div className='w-11/12 max-w-7xl flex flex-col gap-y-8'>
          <h3 className='text-white font-semibold text-2xl sm:text-3xl md:text-4xl text-center px-4'>
            Our Adventure Packages
          </h3>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full'>
            {/* <Card 
              img={villa1} 
              title="Almond Villa" 
              address="Boulevard Road, Srinagar" 
              oldPrice="8999" 
              newPrice="5999"
            /> */}

            {adventure
            .slice(0, 3) // Limit to the first three records directly
            .map((event, index) => (
              <Card 
              img={`https://api.travoolah.com/${event.image}`} // Adjust the image path if necessary
              title={event.title}
              duration={event.duration} 
              oldPrice={`${event.price + 1500}`}
              newPrice={event.price} 
              address={event.description}
            />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Villas