import React from 'react'
import Slider from '../Components/Common/Slider'
import villa1 from "../Assets/villa1.jpg"
import villa2 from "../Assets/villa2.jpg"
import villa3 from "../Assets/villa3.jpg"

import VillasPage from '../Components/Villas/VillasPage'

function Villas() {
    const villasImage = [villa1 , villa2 , villa3];
  return (
    <div className="relative pt-16 flex flex-col items-center ">
        <div className="relative w-full overflow-hidden">
            <Slider images={villasImage} height={"h-[60vh]"} />
        </div>
        <VillasPage />
    </div>
  )
}

export default Villas