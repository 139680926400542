import React from 'react'
import PhotoshootsTopSec from '../Components/Photoshoots/PhotoshootsTopSec'
import PhotographyServices from '../Components/Photoshoots/PhotographyServices'
import VideographyServices from '../Components/Photoshoots/VideographyServices'
import Other from '../Components/Photoshoots/Other'

function PhotoShoots() {
  return (
    <div className="w-full overflow-x-hidden">
      <PhotoshootsTopSec />
      <PhotographyServices />
      <VideographyServices />
      <Other />
    </div>
  )
}

export default PhotoShoots