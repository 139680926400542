import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../Assets/logo.svg';
import cabs from '../../Assets/taxi.svg';
import travel from '../../Assets/travelPackages.svg';
import shoots from '../../Assets/shoots.svg';
import EventManagement from '../../Assets/eventManagement.svg';
import Adventure from "../../Assets/adventure.svg";
import Wellness from "../../Assets/wellness.svg";
import Hotels from "../../Assets/hotels.svg";
import Villas from "../../Assets/villas.svg";
import dots from "../../Assets/threeDots.svg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    setIsLoggedIn(!!token);
  }, []);

  const handleSelect = (item) => {
    setSelected(item);
    setIsOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    setIsLoggedIn(false);
    window.location.href = '/admin/login';
  };

  const navItems = [
    { name: 'Hotels', icon: Hotels, link: '/hotels' },
    { name: 'Villas', icon: Villas, link: '/villas' },
    { name: 'Travel Packages', icon: travel, link: '/travel-packages' },
    { name: 'Transportation', icon: cabs, link: '/transportation' },
    { name: 'Adventure', icon: Adventure, link: '/adventure' },
    {
      name: "Others", icon: dots, link: '',
      subItems: [
        { name: 'Photography & Videography', icon: shoots, link: '/photoShoots' },
        { name: 'Event Management', icon: EventManagement, link: '/eventManagement' },
        { name: 'Wellness and Relaxation', icon: Wellness, link: '/wellness' },
      ],
    },
  ];

  return (
    <nav className="bg-white fixed w-full z-50 top-0 left-0 right-0 drop-shadow-lg">
      <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto py-3 px-5">
        <Link to="/" className="flex items-center" onClick={() => { handleSelect(''); window.scrollTo(0, 0); }}>
          <img src={logo} className="h-12 w-40 object-cover" alt="Logo" />
        </Link>
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        >
          <span className="sr-only">Open main menu</span>
          <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </button>
        <div className={`${isOpen ? 'block' : 'hidden'} w-full md:block md:w-auto`} id="navbar-default">
          <ul className="font-medium flex flex-col md:flex-row md:space-x-8 mt-4 md:mt-0">
            {navItems.map((item) => (
              <li key={item.name} className="relative flex flex-col transition duration-300 cursor-pointer items-center group">
                {item.subItems ? (
                  <div
                    className="flex items-center gap-x-2 py-2 pl-3 pr-4 text-gray-900 md:p-0"
                    onClick={() => setDropdownOpen(dropdownOpen === item.name ? '' : item.name)} // Toggle dropdown open/close for mobile view
                    onMouseEnter={() => setDropdownOpen(item.name)} // Open dropdown on hover for desktop view
                  >
                    <span className="flex items-center gap-x-2">
                      <img src={item.icon} alt={item.name} className="h-5 w-5" />
                      <span className="text-base">{item.name}</span>
                    </span>
                    {dropdownOpen === item.name && (
                      <ul className="absolute top-full right-0 min-w-72 bg-white border rounded shadow-lg mt-2">
                        {item.subItems.map((subItem) => (
                          <li key={subItem.name} className="px-4 py-2 hover:bg-gray-100">
                            <Link
                              to={subItem.link}
                              className="flex items-center gap-x-2"
                              onClick={() => { 
                                handleSelect(subItem.name.toLowerCase()); 
                                window.scrollTo(0, 0); 
                                setIsOpen(false); 
                                setDropdownOpen(''); // Close dropdown after selecting an item
                              }}
                              
                            >
                              <img src={subItem.icon} alt={subItem.name} className="h-5 w-5" />
                              <span className="text-base">{subItem.name}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ) : (
                  <Link
                    to={item.link}
                    className={`flex items-center gap-x-2 py-2 pl-3 pr-4 ${selected === item.name.toLowerCase() ? 'text-[#F26222]' : 'text-gray-900'} md:p-0`}
                    onClick={() => { handleSelect(item.name.toLowerCase()); window.scrollTo(0, 0); setIsOpen(false); setDropdownOpen(false);}}
                  > 
                    <img src={item.icon} alt={item.name} className="h-5 w-5" />
                    <span className="text-base">{item.name}</span>
                  </Link>
                )}
                <div className={`w-full h-1 rounded-lg bg-[#F26222] mt-2 transition-opacity duration-300 ${selected === item.name.toLowerCase() ? 'opacity-100' : 'opacity-0'}`}></div>
              </li>
            ))}
          </ul>
        </div>

        {isLoggedIn && (
          <div className="home-container">
            <button onClick={handleLogout} className="p-3 bg-red-500 text-white rounded hover:bg-red-600">Logout</button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
