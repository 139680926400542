import React from 'react'
import EventManagementTopSec from '../Components/EventPageComponents/EventManagementTopSec'
import DestinationWedding from '../Components/EventPageComponents/DestinationWedding'
import CorporateEvent from '../Components/EventPageComponents/CorporateEvent'
import ThemeParties from '../Components/EventPageComponents/ThemeParties'

function EventManagementPage() {
  return (
    <div className="w-full overflow-x-hidden">
        <EventManagementTopSec />
        <DestinationWedding />
        <CorporateEvent />
        <ThemeParties />
    </div>
  )
}

export default EventManagementPage